//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    Header: () => import("@/components/Header"),
    selMember: () => import("@/components/Selectors/MemberSelectCopy"),
  },
  data() {
    return {
      chartLoad: false,
      xAxisData: [2, 4, 6, 8, 10, 12, 14],
      seriesData: [120, 132, 101, 134, 90, 230, 210],
      timer: null,
      selRow: null, // 选择的某一行
      tabType: 1,
      tabList: [
        {
          value: 1,
          label: "实时上传",
        },
        {
          value: 2,
          label: "实时下载",
        },
      ],
      tableData: [{}, {}],
      pageData: {
        pageIndex: 1,
        pageSize: 15,
        totalNum: 0,
      }, //分页
      loading: false,
      teamOptions: [], //团队选择器
      teamValue: null, //选择的团队
      selMem: [], //选择的成员
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    this.getTeams();
  },
  methods: {
    /**
     * 关闭弹窗
     */
    closeChart() {
      this.selRow = null;
      clearInterval(this.timer);
      this.timer = null;
    },
    /**
     * 点击某一个人
     */
    rowClick(row, column, event) {
      console.log(row, column, event);
      this.selRow = 1;
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.setTimer();
    },
    /**
     * 流量折线图
     */
    drawLine() {
      var echarts = require("echarts");
      var chartDom = document.getElementById("chart");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "40%",
          right: "10px",
          data: ["陈晓雅"],
        },
        grid: {
          left: "60px",
          bottom: "28px",
          top: "20px",
        },
        xAxis: {
          type: "category",
          data: this.xAxisData,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "陈晓雅",
            type: "line",
            stack: "总量",
            data: this.seriesData,
            color: ["#409EFF"],
          },
        ],
      };
      option && myChart.setOption(option);
    },
    /**
     * 定时器发送websocket返回实时流量
     */
    setTimer() {
      if (this.timer == null) {
        this.chartLoad = true;
        this.timer = setInterval(() => {
          this.xAxisData.push(this.xAxisData[this.xAxisData.length - 1] + 2);
          this.seriesData.push(100);
          this.drawLine();
          this.chartLoad = false;
        }, 2000);
      }
    },
    /**
     * tab视图切换
     */
    tabChange(val) {
      this.tabType = val;
    },
    /**
     * 获取列表数据
     */
    handleGetData() {
      if (!this.teamValue) {
        this.$message({
          message: "请先选择团队",
          type: "warning",
        });
        return;
      }
      this.pageData.pageIndex = 1;
      this.tableData = [];
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    /**
     * 获取团队
     */
    getTeams() {
      this.$http
        .get("/Teams/GetAllTeams.ashx", {
          params: { searchText: null, type: 2 },
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.teamOptions = resp.data;
          }
        });
    },
    /**
     * 选择成员
     */
    getSelMember(arr) {
      this.selMem = arr;
    },
    /**
     * 团队切换
     */
    handleTeamChange() {
      this.selMem = [];
    },
    attenceHeaderStyle() {
      return {
        color: "#909399",
      };
    },
  },
};
